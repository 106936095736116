// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apie-mus-js": () => import("./../../../src/pages/apie_mus.js" /* webpackChunkName: "component---src-pages-apie-mus-js" */),
  "component---src-pages-draugai-js": () => import("./../../../src/pages/draugai.js" /* webpackChunkName: "component---src-pages-draugai-js" */),
  "component---src-pages-galerija-js": () => import("./../../../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kaina-js": () => import("./../../../src/pages/kaina.js" /* webpackChunkName: "component---src-pages-kaina-js" */),
  "component---src-pages-kontaktai-js": () => import("./../../../src/pages/kontaktai.js" /* webpackChunkName: "component---src-pages-kontaktai-js" */),
  "component---src-pages-praktine-informacija-js": () => import("./../../../src/pages/praktine_informacija.js" /* webpackChunkName: "component---src-pages-praktine-informacija-js" */),
  "component---src-pages-specialistai-js": () => import("./../../../src/pages/specialistai.js" /* webpackChunkName: "component---src-pages-specialistai-js" */),
  "component---src-pages-tevu-atsiliepimai-js": () => import("./../../../src/pages/tevu_atsiliepimai.js" /* webpackChunkName: "component---src-pages-tevu-atsiliepimai-js" */),
  "component---src-pages-ugdymas-js": () => import("./../../../src/pages/ugdymas.js" /* webpackChunkName: "component---src-pages-ugdymas-js" */)
}

