module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"white","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/images/icons/favicon.png","cache_busting_mode":"none","name":"musu-pedutes","short_name":"musu-pedutes","description":"MŪSŲ PĖDUTĖS – tai Vilniuje, šalia Pašilaičių ir Perkūnkiemio, veikiantis privatus vaikų darželis gryname ore, gamtos ramumoje, kuris priima vaikučius nuo 1 metukų","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","theme_color_in_head":false,"display":"standalone","crossOrigin":"use-credentials","legacy":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-ME5MY6JW32","cookieName":"musupedutes-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"facebookPixel":{"pixelId":"1126815844440120","cookieName":"musupedutes-gdpr-facebook-pixel"},"environments":["production","development"]},
    }]
